import React, { useState } from 'react';
import saas from '../images/SaaS.webp';
import daas from '../images/DaaS.webp';
import { Banner } from './Banner';
import '../css/servicios.css';

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const Servicios = ({ tipo, setRuta, mobile }) => {

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const seccionArchivo = (archivo) => {
        return (
            <div className={`container d-flex justify-content-center mt-5 ${mobile ? 'mobile-pdf-container' : ''}`}
                style={{ maxWidth: "100%", maxHeight: mobile ? "100vh" : "63vh", overflow: "auto" }}>
                <Document file={`/files/${archivo}.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={mobile ? 0.5 : 1.0} />
                    ))}
                </Document>
            </div>
        );
    }

    return (
        <>

            {tipo === 'saas' &&
                <section id={tipo}>
                    {seccionArchivo('xaas')}
                </section>
            }

            {tipo === 'daas' &&
                <section id="daas" className=" mt-3" /* style={{ backgroundColor: '#f8f9fa' }} */>
                    {seccionArchivo('daas')}
                </section>
            }

            {tipo === 'xaas' &&
                <section id="xaas" className=" mt-3" /* style={{ backgroundColor: '#f8f9fa' }} */>
                    {seccionArchivo('xaas')}
                </section>
            }

        </>
    )
}