import React from 'react'
import Aliado1 from '../images/Aliado1.png';
import Aliado2 from '../images/Aliado2.png';
import Aliado3 from '../images/Aliado3.png';
import Aliado4 from '../images/Aliado4.png';
import Aliado5 from '../images/Aliado5.png';
import Aliado6 from '../images/Aliado6.png';
import Aliados_ from '../images/Aliados.png';
import '../css/aliados.css';

export const Aliados = () => {

    const CarouselItem = ({ img1, width1 }) => (
        <div className="col-md-4 d-flex justify-content-center align-items-center">
            <img src={img1} className="d-block mx-auto" alt="Aliado 1"
                style={{ width: width1, height: '160px', objectFit: 'contain' }} />
        </div>
    );

    return (
        <>
            <section id="aliados" className="py-5">
                <div className="container">
                    <h2 className="text-center mb-4">Nuestros Aliados</h2>
                    <img
                        src={Aliados_}
                        alt=""
                        className="img-fluid"
                    />
                    {/* <div id="carouselAliados" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">
                        <div className="carousel-inner">

                            <div className="carousel-item active">
                                <div className="row">
                                    <CarouselItem img1={Aliado1} width1={'215px'} />
                                    <CarouselItem img1={Aliado2} width1={'200px'} />
                                    <CarouselItem img1={Aliado3} width1={'200px'} />
                                </div>
                            </div>

                            <div className="carousel-item">
                                <div className="row">
                                    <CarouselItem img1={Aliado4} width1={'195px'} />
                                    <CarouselItem img1={Aliado5} width1={'200px'} />
                                    <CarouselItem img1={Aliado6} width1={'200px'} />
                                </div>
                            </div>

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselAliados" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselAliados" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div> */}
                </div>
            </section>

        </>
    )
}
