import React, { Fragment, useEffect, useState } from 'react';
import '../css/Estilo.css';
import logo from '../images/logoAB.png';
import banner from '../images/banner.webp';

import { FloatingWhatsApp } from 'react-floating-whatsapp'

import { NavBar } from './NavBar';
import { Banner } from './Banner';
import { Aliados } from './Aliados';
import { TarjetaServicio } from './TarjetaServicio';
import Footer from './Footer';
import { AcercaDe } from './AcercaDe';
import { Servicios } from './Servicios';
import { Servicios2 } from './Servicios2';
import CookieConsent from 'react-cookie-consent';

export const Home = () => {

    const rutaSS = sessionStorage.getItem('ruta');
    const tipoSS = sessionStorage.getItem('tipo');
    const [ruta, setRuta] = useState(rutaSS || 'home');
    const [tipo, setTipo] = useState(tipoSS || 'saas');
    const [cookieAccepted, setCookieAccepted] = useState(false);
    const handleAccept = () => { setCookieAccepted(true) };
    const handleReject = () => { setCookieAccepted(false) };
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        sessionStorage.setItem('ruta', ruta);
        sessionStorage.setItem('tipo', tipo);
    }, [ruta, tipo]);

    /* scroll function */
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const nav = document.querySelector('.navbar');
            if (window.scrollY > 50) setScroll(true);
            else setScroll(false);
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll) };
    }, []);
    /* scroll function */

    useEffect(() => {
        if (window.innerWidth < 768) setMobile(true);
        else setMobile(false);
    }, []);

    return (
        <>

            <FloatingWhatsApp
                phoneNumber="57 3155002514"
                accountName="Advance Business"
                allowEsc
                allowClickAway
                notification
                notificationSound
                chatMessage={`Tienes alguna pregunta sobre algun Servicio ?`}
                buttonClassName="me-4"
                buttonStyle={{ marginBottom: '80px' }}
                chatboxStyle={{ marginBottom: '70px' }}
                avatar={logo}
            />

            <CookieConsent
                location="bottom"
                buttonText="Aceptar"
                enableDeclineButton
                declineButtonText="Rechazar"
                cookieName="miCookie"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                onAccept={handleAccept}
                onDecline={handleReject}
            >Este sitio  utiliza cookies para mejorar la experiencia del usuario.
            </CookieConsent>

            <NavBar
                scroll={scroll}
                logo={logo}
                setRuta={setRuta}
                setTipo={setTipo}
                ruta={ruta}
            />


            {ruta === 'home' &&
                <Fragment>
                    <Banner id={'hero'}
                        imagen={banner}
                        titulo={'Advance Business SAS'}
                        texto={'Innovación y Tecnología para Impulsar tu Negocio'} />
                    <AcercaDe />
                    <TarjetaServicio
                        setTipo={setTipo}
                        setRuta={setRuta} />
                    <Aliados />
                </Fragment>}


            {/* {ruta === 'servicios' &&
                <Servicios
                    tipo={tipo}
                    setRuta={setRuta}
                    mobile={mobile} />} */}

            {ruta === 'servicios2' &&
                <Servicios2 tipo={tipo}
                    setRuta={setRuta}
                    mobile={mobile} />}


            <Footer scroll={scroll} logo={logo} />
        </ >
    );
};
