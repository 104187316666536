import React from 'react';
import saas from '../images/SaaS.webp';
import xaas from '../images/XaaS.webp';
import daas from '../images/DaaS.webp';

export const TarjetaServicio = ({ setTipo, setRuta }) => {

    const cardAb = (titulo, subtitulo, texto, imagen, funcion) => {
        return (
            <div className="col-md-4 d-flex justify-content-center mb-4">
                <div className="card" style={{ width: '18rem', borderRadius: '25px' }}>
                    <img src={imagen}
                        className="card-img-top"
                        alt="..."
                        height={200}
                        style={{
                            borderTopRightRadius: '25px',
                            borderTopLeftRadius: '25px'
                        }} />
                    <div className="card-body">
                        <h5 className="card-title"><strong>{titulo}</strong></h5>
                        <h6 className='card-subtitle mb-3'>{subtitulo}</h6>
                        <p className="card-text">
                            {texto}
                        </p>
                        <button className="btn btn-outline-primary"
                            onClick={funcion}
                        >Más Información</button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <section id="servicios" className="py-5" style={{ backgroundColor: '#f8f9fa' }}>
            <div className="container text-center">
                <h2 className="mb-4">Nuestros Servicios</h2>
                <div className="row justify-content-center">
                    {/* {cardAb('Saas',
                        'Software as a Service',
                        'Simplifique su operación con nuestras soluciones SaaS, ofreciendo software en la nube seguro, escalable y optimizado para las necesidades de su negocio, garantizando eficiencia y disponibilidad en todo momento.',
                        saas,
                        () => {
                            setRuta('servicios')
                            setTipo('saas')
                        }
                    )} */}
                    {cardAb('Xaas',
                        'Everything as a Service',
                        'Maximice la eficiencia y flexibilidad de su negocio con nuestras soluciones XaaS, un modelo integral que optimiza la adquisición, gestión y mantenimiento de tecnología.', //ofreciendo infraestructura, software y dispositivos como servicio con costos predecibles y alto rendimiento
                        xaas,
                        () => {
                            setRuta('servicios2')
                            setTipo('xaas')
                        }
                    )}
                    {cardAb('DaaS',
                        'Device as a Service',
                        'Optimice su negocio con nuestro servicio DaaS: una solución integral que ofrece flexibilidad, mantenimiento, seguridad y gestión de dispositivos, garantizando continuidad operativa y control eficiente de costos.',
                        daas,
                        () => {
                            setRuta('servicios2')
                            setTipo('daas')
                        }
                    )}
                </div>
            </div>
        </section>
    );
};