import React from 'react'
import '../css/footer.css'

const Footer = ({ scroll, logo }) => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>

            {/* <!-- Footer --> */}
            <div className="container-fluid text-white-50 footer bg-dark" id='footer'>
                <div className="container py-4">
                    <div className="row g-5 text-center">

                        {/* Col1 */}
                        {/* <div className="col-md-6 col-lg-4" > */}
                        <div className="col-md-6 col-lg-6" >
                            <img src={logo}
                                width="130"
                                alt="logo_white" />
                            <div className='mt-2'>Desde 2009, nos dedicamos a proporcionar soluciones tecnológicas innovadoras y personalizadas, diseñadas para satisfacer las necesidades específicas de nuestros clientes en toda Colombia.</div>
                            {/* <div className='mt-2'>
                                <a className='m-1'
                                    href="https://www.facebook.com/" rel="noreferrer" target="_blank">
                                    <i className="fab fa-facebook" />
                                </a>
                                <a className='m-1'
                                    href="https://www.instagram.com/" rel="noreferrer" target="_blank">
                                    <i className="fab fa-instagram" />
                                </a>
                                <a className='m-1'
                                    href="https://www.linkedin.com/" rel="noreferrer" target="_blank">
                                    <i className="fa-brands fa-linkedin" />
                                </a>
                            </div> */}

                        </div>

                        {/* col2 */}
                        {/* <div className="col-md-6 col-lg-4">
                            <h6 className="text-uppercase font-weight-bold text-white mt-5 mt-xs-">Enlaces Importantes</h6>
                            <p className='my-0'><i className="fa-solid fa-align-justify me-3"/>Términos y Condiciones</p>
                            <p className='my-0'><i className="fa-solid fa-file-shield me-3"/>Politica-tratamiento-de-datos.pdf</p>
                            <p className='my-0'><i className="fa-solid fa-question me-3"/>F.A.Q</p>
                        </div> */}

                        {/* col3 */}
                        {/* <div className="col-md-12 col-lg-4"> */}
                        <div className="col-md-6 col-lg-6">
                            <p className='mb-0 mt-3'><i className="fa-solid fa-link me-3 mt-3" />www.advancebusiness.com.co</p>
                            <p className='my-0'><i className="fa fa-phone-alt me-3" />+ 315 500 25 14</p>
                            <p className='my-0'>
                                <i className="fa fa-envelope me-3" />
                                <a href="mailto:info@advancebusiness.com.co" className="text-white">info@advancebusiness.com.co</a>
                            </p>
                            {/* <img src={intelTitanium} height="50" className='mt-3' /> */}
                        </div>
                    </div>
                </div>

                <div className="container text-center">
                    <div className="copyright" style={{ fontSize: '12px' }}>
                        <div className="row">
                            <div className="col-md-6 text-center  mb-md-0">
                                <p className='my-2'>Copyright &copy; Advance business SAS.  All Right Reserved 2025</p>
                            </div>
                            <div className="col-md-6 text-center text-md-center mb-md-0">
                                <p className='my-2'>by LivenUP!</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Back to Top --> */}
                {scroll &&
                    <button
                        aria-label="toTop"
                        className="btn btn-lg btn-lg-square text-white border-light position-fixed bottom-0 end-0 m-3"
                        onClick={scrollToTop}>
                        <i className="fa-solid fa-arrow-up"></i>
                    </button>
                }
            </div>
            {/* <!-- Footer --> */}

        </>
    )
}

export default Footer