import React, { useEffect, useState } from 'react';
import saas from '../images/SaaS.webp';
import daas from '../images/DaaS.webp';
import { Banner } from './Banner';
import '../css/servicios.css';
import finansa from '../images/Finansa.png';
import ventajas from '../images/ventajas.png';
import Daas from '../images/Dass.png';
import Iaas from '../images/Iaas.png';
import Nube from '../images/Nube.png';
import Nube2 from '../images/Nube2.png';
import Mantenimiento from '../images/Mantenimiento.png';
import Soporte from '../images/Soporte.png';
import Xaass from '../images/Xass.png';
import DaAs from '../images/Prueba.png';
import Hass from '../images/Hass.png';
import { FaLaptop, FaShieldAlt, FaSyncAlt, FaTools } from 'react-icons/fa';
import hAss from '../images/haas.png';
import Device1 from '../images/Device1.png';
import Device2 from '../images/Device2.png';
import Optimizazion from '../images/Optimizacion.png';
import Flexibilidad from '../images/Flexibilidad.png';
import Entorno from '../images/Entorno.png';


export const Servicios2 = ({ tipo, setRuta, mobile }) => {

    //---------------------------------------------------------------------

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => { scrollToTop(); }, [tipo, setRuta, mobile]);

    const seccionTextoIzquierda = (titulo, subtitulo, texto, imagen) => {
        const oraciones = texto.split('.').filter(oracion => oracion.trim() !== '');

        return (
            <section id="xaas" className="mt-5">
                <div className="container my-5 py-5 rounded-4 shadow-sm" >
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <h4 className="fw-semibold text-dark display-6 mb-2">
                                {titulo}
                            </h4>
                            <h6 className="text-primary fw-light fst-italic mb-3">
                                {subtitulo}
                            </h6>
                            <ul className="lead">
                                {oraciones.map((oracion, index) => (
                                    <li key={index} className='text-start ms-4'>{oracion.trim()}.</li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 text-center">
                            <img
                                src={imagen}
                                alt="Imagen descriptiva"
                                style={{ maxWidth: 350 }}
                                className="img-fluid rounded-3 shadow"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    const seccionTextoDerecha = (titulo, subtitulo, texto, imagen) => {
        const oraciones = texto.split('.').filter(oracion => oracion.trim() !== '');

        return (
            <section id="xaas" className="mt-5">
                <div className="container my-5 py-5 rounded-4 shadow-sm" >
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 text-center">
                            <img
                                src={imagen}
                                alt="Imagen descriptiva"
                                style={{ maxWidth: 350 }}
                                className="img-fluid rounded-3 shadow"
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <h4 className="fw-semibold text-dark display-6 mb-2">
                                {titulo}
                            </h4>
                            <h6 className="text-primary fw-light fst-italic mb-3">
                                {subtitulo}
                            </h6>
                            <ul className="lead">
                                {oraciones.map((oracion, index) => (
                                    <li key={index} className='text-start ms-4'>{oracion.trim()}.</li>
                                ))}
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
        );
    };

    const xaas = (imagen, texto1, texto2, direccion) => {
        return (
            <>
                <div className=" col-md-4 mb-3 align-self-center justify-content-center">
                    <div className="card align-items-center me-2 mb-2"
                        style={{ maxWidth: mobile ? '' : "18rem",
                                marginLeft: mobile ? 'auto' : '',
                        }}>
                        <img src={imagen}
                            className="card-img-left img-fluid"
                            alt="..."
                            style={{ width: "6rem", height: "6rem", objectFit: "cover" }} />
                        <div className="card-body">
                            <p className="card-text">{texto1} <br /> {texto2}</p>
                            {direccion &&
                                <i className={`fa-solid fa-arrow-${mobile ? 'down' : direccion}`} />
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    //---------------------------------------------------------------------

    const tituloDaas = () => {
        return (
            <div className="container mt-5">
                <div className="bg-primary text-white text-center py-4">
                    <h2 className="fw-bold">DEVICES AS A SERVICES (DaaS)</h2>
                    <div className="mx-auto border-bottom border-light w-50 mb-2"></div>
                    <p className="fw-light">FLEXIBILIDAD PARA SU NEGOCIO</p>
                </div>
            </div>
        );
    };

    const tarjetaDaas = (imagen, titulo, subtitulo, texto, arriba) => {
        const oraciones = texto.split('.').filter(oracion => oracion.trim() !== '');
        return (
            <div className="col-md-4 mb-4">
                <div className="card h-100 text-center">
                    {arriba === 1 && <h5 className="card-title text-white bg-danger p-2 mb-0">{titulo} <br /> {subtitulo}</h5>}
                    <div className="d-flex justify-content-center">
                        <img src={imagen}
                            className="card-img-top img-fluid"
                            alt="Optimización TI"
                            style={{ width: '100%', height: '300px', objectFit: 'cover' }}
                        />
                    </div>
                    <div className="card-body p-0">
                        {arriba !== 1 && <h5 className="card-title text-white bg-danger p-2">{titulo} <br /> {subtitulo}</h5>}

                        <ul className="lead">
                            {oraciones.map((oracion, index) => (
                                <li key={index}
                                    className='text-start ms-4'
                                    style={{ fontSize: '1rem' }}
                                >{oracion.trim()}.</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    const ServiceScope = () => {
        return (
            <div className="container mt-5">
                <h3 className="text-center text-danger text-uppercase fw-bold">Alcance del Servicio</h3>

                <div className="row text-center mt-4">
                    {/* Columna 1 */}
                    <div className="col-md-3 mb-4 d-flex align-items-stretch">
                        <div className="card p-2 rounded shadow-sm w-100 d-flex flex-column">
                            <h6 className="mb-3 bg-primary text-white p-2 rounded"><FaLaptop className="me-2" />1. Servicios Básicos TI</h6>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Alistamiento de Equipos</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Creación & Despliegue de Imágenes</div>
                            <div className="bg-danger text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Servicios Logísticos</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Migración de Datos</div>
                            <div className="bg-danger text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Seguros Equipos</div>
                        </div>
                    </div>

                    {/* Columna 2 */}
                    <div className="col-md-3 mb-4 d-flex align-items-stretch">
                        <div className="card p-2 rounded shadow-sm w-100 d-flex flex-column">
                            <h6 className="mb-3 bg-primary text-white p-2 rounded"><FaSyncAlt className="me-2" />2. Continuidad del Servicio</h6>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Equipos de Back Up</div>
                            <div className="bg-danger text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Garantías de Fábrica (Originales & Extend)</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Protección contra Daños Accidentales</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">ANS</div>
                        </div>
                    </div>

                    {/* Columna 3 */}
                    <div className="col-md-3 mb-4 d-flex align-items-stretch">
                        <div className="card p-2 rounded shadow-sm w-100 d-flex flex-column">
                            <h6 className="mb-3 bg-primary text-white p-2 rounded"><FaTools className="me-2" />3. Gestión IT de Dispositivos</h6>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Geolocalización de Equipos</div>
                            <div className="bg-danger text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Administración Activos & Reportes de Desempeño</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Soporte Remoto</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Actualizaciones (O.S – Drivers)</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Predictibilidad ante Fallos de HDW</div>
                        </div>
                    </div>

                    {/* Columna 4 */}
                    <div className="col-md-3 mb-4 d-flex align-items-stretch">
                        <div className="card p-2 rounded shadow-sm w-100 d-flex flex-column">
                            <h6 className="mb-3 bg-primary text-white p-2 rounded"><FaShieldAlt className="me-2" />4. Seguridad de la Información</h6>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Borrado Seguro</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Gestión de Versiones & Aplicaciones</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Cifrado de Archivos</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Respaldo de Datos (20 GB Max)</div>
                            <div className="bg-dark text-white p-1 mt-2 rounded shadow-sm flex-grow-1">Predictibilidad ante Fallos de HDW</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    //---------------------------------------------------------------------

    const AcquisitionOptions = () => {
        return (
            <div className="container mt-5">
                {/* Título */}
                <h3 className="text-center text-danger text-uppercase fw-bold">
                    Modalidades de Adquisición:
                    <br />
                    <span className="text-black">Alternativas que se ajustan a sus necesidades financieras</span>
                </h3>

                {/* Sección de opciones */}
                <div className="row mt-4">
                    {/* HaaS */}
                    <div className="col-md-6 mb-4">
                        <div className="card border-0 shadow-sm h-100">
                            <div className="card-header bg-danger text-white text-center fw-bold">
                                HaaS Hardware as a Service
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <img src={Hass} className="card-img-top" alt="HaaS" style={{ width: '60%', height: 'auto' }} />
                            </div>
                            <div className="card-body text-center">
                                <ul className="list-unstyled">
                                    <li className="mb-2">Alquiler con opción de compra</li>
                                    <li className="mb-2">Es una figura netamente financiera con pagos mensuales</li>
                                    <li className="mb-2">Deducible de renta (gasto operativo)</li>
                                    <li className="mb-2">Menor inversión inicial, acceso a tecnología moderna</li>
                                    <li className="mb-2">Existe un equipo de TI que soporta a usuarios finales</li>
                                    <li className="mb-2">Asume costos de mantenimiento y operación del activo</li>
                                    <li className="mb-2">Se puede apalancar en garantías del fabricante</li>
                                    <li className="mb-2">Devolución del activo o renovación por equipos nuevos</li>
                                    <li className="mb-2">Minimiza riesgos derivados del avance tecnológico</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* DaaS */}
                    <div className="col-md-6 mb-4">
                        <div className="card border-0 shadow-sm h-100">
                            <div className="card-header bg-danger text-white text-center fw-bold">
                                DaaS Device as a Service
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <img src={DaAs} className="card-img-top" alt="DaaS" style={{ width: '60%', height: 'auto' }} />
                            </div>
                            <div className="card-body text-center">
                                <ul className="list-unstyled">
                                    <li className="mb-2">Activo inmerso en una capa de servicios</li>
                                    <li className="mb-2">Pago por suscripción</li>
                                    <li className="mb-2">Deducible como gasto operativo</li>
                                    <li className="mb-2">Flexibilidad, escalabilidad, sin inversión inicial</li>
                                    <li className="mb-2">Equipo de TI dedicado a apoyo estratégico</li>
                                    <li className="mb-2">Contratos</li>
                                    <li className="mb-2">Reduce costos de actualizaciones y obsolescencia</li>
                                    <li className="mb-2">El activo es un componente dentro del servicio</li>
                                    <li className="mb-2">Reduce riesgos derivados de avances tecnológicos</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    //---------------------------------------------------------------------

    return (
        <>

            {tipo === 'xaas' &&
                <section id={tipo} className="pt-5">
                    {seccionTextoIzquierda(
                        'XaaS Colombia:',
                        'Financiación y Modelo de Negocio',
                        'Financiación de proyectostecnológicos para PYMES y clientescorporativos.' +
                        'Beneficios de deducción de impuestos. Ningún impacto de deuda en usuariosfinales.' +
                        'Flujo de caja para continuidad del giroordinario del negocio.' +
                        'Factoring de proyectos con OC decliente y cesión de derechoseconómicos de la factura.',
                        finansa,
                    )}


                    {mobile
                        ? seccionTextoIzquierda(
                            'Ventajas Modelo XaaS',
                            'by Advance Busines',
                            'Alquiler de hardware, software y servicios de soporte.' +
                            'Experiencia única de HaaS-DaaS: usuario, gestión de activos,continuidad del negocio.' +
                            'Adaptable a todos los presupuestos.' +
                            'Gestion de IT Centralizada.' +
                            'Eficiencia en costos (Costo en línea con el perfil de riesgo de crédito del Cliente). ' +
                            'Eficiencia en costos (Bajos costos de set-up comparadocon otras ofertas). ' +
                            'XaaS SERVICES puede ser implementado en 4 semanas. ' +
                            'Mantiene los procesos de administración/manejo de las cuentaspor cobrar del cliente. ' +
                            'Mayor seguridad',
                            ventajas
                        )
                        : seccionTextoDerecha(
                            'Ventajas Modelo XaaS',
                            'by Advance Busines',
                            'Alquiler de hardware, software y servicios de soporte.' +
                            'Experiencia única de HaaS-DaaS: usuario, gestión de activos,continuidad del negocio.' +
                            'Adaptable a todos los presupuestos.' +
                            'Gestion de IT Centralizada.' +
                            'Eficiencia en costos (Costo en línea con el perfil de riesgo de crédito del Cliente). ' +
                            'Eficiencia en costos (Bajos costos de set-up comparadocon otras ofertas). ' +
                            'XaaS SERVICES puede ser implementado en 4 semanas. ' +
                            'Mantiene los procesos de administración/manejo de las cuentaspor cobrar del cliente. ' +
                            'Mayor seguridad',
                            ventajas
                        )
                    }

                    {/* Servicios Section */}
                    <section>
                        <div className="container my-5 py-5 rounded-4 shadow-sm text-center" >
                            <h2 className="text-center mb-4">XaaS SERVICES</h2>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-8 justify-content-center">
                                    <div className="row justify-content-center align-items-center">
                                        {xaas(Daas, "Device as a service ", '(DaaS)', 'right')}
                                        {xaas(hAss, "Hardware as a Service ", '(HaaS)', 'right')}
                                        {xaas(Iaas, "Infrastructure as a Service ", '(IaaS)', 'down')}
                                        {mobile
                                            ? <>
                                                {xaas(Nube, "Migración a ", 'la Nube', 'left')}
                                                {xaas(Nube2, "Journey ", 'to Cloud', 'left')}
                                                {xaas(Soporte, "Mesas ", 'de Ayuda', 'down')}
                                            </>
                                            : <>
                                                {xaas(Soporte, "Mesas ", 'de Ayuda', 'down')}
                                                {xaas(Nube2, "Journey ", 'to Cloud', 'left')}
                                                {xaas(Nube, "Migración a ", 'la Nube', 'left')}
                                            </>
                                        }

                                        {xaas(Mantenimiento, "Servicios de mantenimiento preventivo y correctivo")}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 justify-content-center">
                                    <img
                                        src={Xaass}
                                        alt="Imagen descriptiva"
                                        style={{ maxWidth: 390 }}
                                        className="img-fluid rounded-3 shadow"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }

            {tipo === 'daas' &&

                <section id="daas" className="pt-5">

                    {tituloDaas()}

                    <section className="container my-5">

                        <h2 className="text-center text-white bg-danger py-3">
                            UN SOLO PROVEEDOR, UNA SOLA FACTURA
                        </h2>

                        <div className="row justify-content-center">
                            {tarjetaDaas(Optimizazion,
                                'OPTIMIZACIÓN & ',
                                'SIMPLIFICACIÓN TI',
                                'Simplifica los procesos TI.' +
                                'Permite asignar recursos a tareas más críticas.' +
                                'Incrementa los niveles de servicio a usuarios internos.' +
                                'Simplifica la logística.' +
                                'Permite la administración de múltiples dispositivos y OS (BYOD).' +
                                'Único punto de contacto para rendición de cuentas.'
                            )}
                            {tarjetaDaas(Flexibilidad,
                                'FLEXIBILIDAD & ',
                                'PREDICTIBILIDAD EN EL GASTO TI',
                                'Pago por Uso: Modelo basado en consumo.' +
                                'Mayor previsibilidad sobre gasto TI.' +
                                'Modelo de gasto alineado con flujo de caja de la Organización.' +
                                'Gastos Operativos (OPEX) y no gastos de Capital (CAPEX).' +
                                'Reduce gastos asociados a actualizaciones (Licencias), obsolescencia.' +
                                'Modelo se ajusta a las necesidades, fluctuaciones propias del negocio.'
                            )}
                            {tarjetaDaas(Entorno,
                                'UN ENTORNO ESTANDARIZADO',
                                'ÀGIL DE GESTIONAR',
                                'Provee un entorno estandarizado y establepara los usuarios.' +
                                'Mejor experiencia para diferentes necesidadesde los usuarios.' +
                                'Mas productividad para los empleados:Diferentes tipos de dispositivos.' +
                                'Soporte a múltiples tipos dispositivos ysistemas operativos (OS).' +
                                'Evita el costoso tiempo de inactividad'
                            )}
                        </div>
                    </section>

                    <div className="container mt-4">
                        <h1 className="titulo text-center text-uppercase fw-bold mt-4">Devices as a Service:</h1>
                        <h2 className="subtitulo text-center text-secondary mb-4">Apoya la Continuidad Operativa de su Negocio</h2>

                        <div className="row mt-4">
                            <div className="col-12 col-md-6 mb-2">
                                <img src={Device1} className="img-fluid" alt="Imagen izquierda" />
                            </div>
                            <div className="col-12 col-md-6 mb-2">
                                <img src={Device2} className="img-fluid" alt="Imagen derecha" />
                            </div>
                        </div>
                    </div>

                    {ServiceScope(
                        'Alcance del Servicio',
                        '1. Servicios Básicos TI',
                        'Alistamiento de Equipos, Creación & Despliegue de Imágenes, Servicios Logísticos, Migración de Datos, Seguros Equipos',
                        '2. Continuidad del Servicio',
                        'Equipos de Back Up, Garantías de Fábrica (Originales & Extend), Protección contra Daños Accidentales, ANS',
                        '3. Gestión IT de Dispositivos',
                        'Geolocalización de Equipos, Administración Activos & Reportes de Desempeño, Soporte Remoto, Actualizaciones (O.S – Drivers), Predictibilidad ante Fallos de HDW',
                        '4. Seguridad de la Información',
                        'Borrado Seguro, Gestión de Versiones & Aplicaciones, Cifrado de Archivos, Respaldo de Datos (20 GB Max), Predictibilidad ante Fallos de HDW'
                    )}

                    {/* {AcquisitionOptions(
                        'Modalidades de Adquisición:',
                        'HaaS Hardware as a Service',
                        'Alquiler con opción de compra, Es una figura netamente financiera con pagos mensuales, Deducible de renta (gasto operativo), Menor inversión inicial, acceso a tecnología moderna, Existe un equipo de TI que soporta a usuarios finales, Asume costos de mantenimiento y operación del activo, Se puede apalancar en garantías del fabricante, Devolución del activo o renovación por equipos nuevos, Minimiza riesgos derivados del avance tecnológico',
                    )} */}

                    <section className="container my-5">
                        <h2 className="text-center text-danger  py-3">
                            Modalidades de Adquisición: <br />
                            Alternativas que se ajustan a sus necesidades financieras
                        </h2>

                        <div className="row justify-content-center">
                            {tarjetaDaas(Hass,
                                'HaaS Hardware',
                                'as a Service',
                                'Alquiler con opción de compra.' +
                                'Es una figura netamente financiera con pagos mensuales.' +
                                'Deducible de renta (gasto operativo).' +
                                'Menor inversión inicial, acceso a tecnología moderna,previsibilidad de costos.' +
                                'Existe un equipo de TI que soporta a usuarios finales.' +
                                'Asume costos de mantenimiento y operación del activodurante su ciclo de vida.' +
                                'Lo relevante es el activo que se puede apalancar engarantías del fabricante.' +
                                'Devolución del activo o renovación por equipos nuevos.' +
                                'Minimizar los riesgos derivados del avance tecnológico.',
                                1
                            )}
                            {tarjetaDaas(DaAs,
                                'DaaS Device',
                                'as a Service',
                                'Activo inmerso en una capa de servicios que garantiza lacontinuidad operativa del negocio.' +
                                'Pago por suscripción.' +
                                'Deducible como gasto operativo.' +
                                'Flexibilidad, escalabilidad, sin inversión inicial, gestiónintegral con mayor flexibilidad para el negocio.' +
                                'Equipo de TI dedicado a apoyo estratégico del negocio.' +
                                'Contratos.' +
                                'Reduce gastos asociados a actualizaciones (Licencias yobsolescencia.' +
                                'Lo relevante es el servicio, el activo es un componentedentro del mismo.' +
                                'Reduce todos los riesgos derivados de los avancestecnológicos.',
                                1
                            )}
                        </div>
                    </section>
                </section>
            }

        </>
    )
}