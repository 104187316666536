import React from 'react';

export const Banner = ({id, imagen, titulo, texto}) => {

    return (
        <section
            id={id}
            className="bg-dark text-white text-center d-flex align-items-center justify-content-center position-relative"
            style={{
                height: '100vh',
                background: `url(${imagen}) no-repeat center center`,
                backgroundSize: 'cover'
            }}>

            <div
                className="container position-relative"
                style={{ zIndex: 1 }}>
                <h1 className="display-3 fw-bold mb-4 text-white">{titulo}</h1>
                <h2 className="fw-light  text-white">{texto}</h2>
            </div>

            <div
                className="overlay position-absolute top-0 start-0 w-100 h-100"
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 0
                }}>
            </div>
        </section>
    )
}
