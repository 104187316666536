import React from 'react';

export const NavBar = ({ scroll, logo, setRuta, setTipo, ruta }) => {

    const handleNavLinkClick = () => {
        const navbarCollapse = document.getElementById('navbarNav');
        if (navbarCollapse.classList.contains('show')) {
            navbarCollapse.classList.remove('show');
        }
    };

    return (

        <nav className={
            `navbar navbar-expand-lg 
            navbar-${!scroll && ruta !== 'servicios' ? 'dark' : 'light'} 
            fixed-top  bg-${!scroll && ruta !== 'servicios' ? 'dark' : 'light'} 
            `}>

            <a className="navbar-brand p-0" href="#">
                <img
                    src={logo}
                    alt="Advance Business SAS Logo"
                    className="logo"
                    style={{ height: "75px" }} />
            </a>
            <img src="" alt="" />
            <button className="navbar-toggler"
                type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarNav" aria-controls="navbarNav"
                aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">

                    <li className="nav-item">
                        <a className="nav-link" href="#hero"
                            onClick={() => {
                                setRuta('home')
                                handleNavLinkClick();
                            }}>Home</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="#acerca"
                            onClick={() => {
                                setRuta('home')
                                handleNavLinkClick();
                            }}
                        >Nosotros</a>
                    </li>

                    {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Servicios
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href="#xaas"
                                onClick={() => {
                                    setRuta('servicios')
                                    setTipo('xaas')
                                    handleNavLinkClick();
                                }}>XaaS</a></li>
                            <li><a className="dropdown-item" href="#daas"
                                onClick={() => {
                                    setRuta('servicios')
                                    setTipo('daas')
                                    handleNavLinkClick();
                                }}>DaaS</a></li>
                        </ul>
                    </li> */}

                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Servicios
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href="#xaas"
                                onClick={() => {
                                    setRuta('servicios2')
                                    setTipo('xaas')
                                    handleNavLinkClick();
                                }}>XaaS</a></li>
                            <li><a className="dropdown-item" href="#daas"
                                onClick={() => {
                                    setRuta('servicios2')
                                    setTipo('daas')
                                    handleNavLinkClick();
                                }}>DaaS</a></li>
                        </ul>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="#aliados"
                            onClick={() => {
                                setRuta('home')
                                handleNavLinkClick();
                            }}
                        >Aliados</a>
                    </li>
                </ul>
            </div>

        </nav>
    )
}
