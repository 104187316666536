import React from 'react';
import '../css/acercade.css';

export const AcercaDe = () => {

    const cajitas = (icono, texto) => {
        return (
            <div className="col-md-4 mb-4">
                <div className="cajita d-flex align-items-center p-4 border rounded-3 shadow-sm"
                    style={{ height: '150px' }}>
                    <i className={`fa ${icono} fa-2x me-3`}></i>
                    <p className="mb-0"> {texto} </p>
                </div>
            </div>
        );
    };

    return (
        <>
            <section id="acerca"
                className="py-5"
                style={{ backgroundColor: '#4679A6' }}
            >
                <div className="container">
                    <h2 className="text-center text-white">Acerca de Advance Business</h2>
                    <div className="row">

                        <div className="text-center my-3 text-white">
                            <p>
                                Advance Business SAS es una empresa fundada en 2009,
                                fabricante local de equipos de cómputo Local OEM y
                                mayorista de tecnología de las principales marcas en el mercado colombiano.
                                Con más de 14 años de experiencia,
                                contamos con las más altas certificaciones de nuestros socios de negocios.
                                <br />
                                Nuestra logística está alineada con nuestros clientes y socios estratégicos
                                desde nuestras instalaciones en Zona Franca Bogotá.
                                <br />
                                Contamos con el respaldo financiero de fondos de inversión nacionales y del exterior para operaciones de montos superiores.
                            </p>
                        </div>



                        <div className="row">
                            {cajitas("fa-flag", "Somos una empresa 100% colombiana.")}
                            {cajitas("fa-briefcase", "Atendemos clientes corporativos y de Gobierno, ofreciendo asesoría y ejecución de proyectos tecnológicos de nivel global.")}
                            {cajitas("fa-map-marker-alt", "Accedemos a todo el territorio colombiano.")}
                            {cajitas("fa-tint", "Advance Business SAS proporciona liquidez adicional para un crecimiento financiero sostenible.")}
                            {cajitas("fa-chart-line", "Ofrecemos un rendimiento comercial y financiero mejorado.")}
                            {cajitas("fa-handshake", "Aportamos solidez a la cadena de distribución.")}
                            {cajitas("fa-project-diagram", "Mejoramos los indicadores de ejecución de proyectos tecnológicos.")}
                            {cajitas("fa-users", "Construimos relaciones superiores con clientes y proveedores.")}
                            {cajitas("fa-cogs", "Ofrecemos soluciones tecnológicas diversas para varias industrias.")}
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};